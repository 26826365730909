<div *ngIf="Header" class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">{{ Title }}</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ Message }}
    <div *ngIf="ShowCheckBox" class="row modal-body">
        <div class="form-group form-check">
            <input style="margin-top:5px;" type="checkbox" [(ngModel)]="sendBulkMail" class="form-check-input"
                id="bulkmail">
            <label class="form-check-label danger" for="bulkmail">Send bulk mails</label>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">{{ CancelButton }}</button>

    <button type="button" class="btn btn-primary" *ngIf="!ShowCheckBox" (click)="onOK()">{{ OKButton }}</button>
    <button type="button" class="btn btn-primary" *ngIf="ShowCheckBox && sendBulkMail" (click)="onOK()">{{ OKButton
        }}</button>

</div>