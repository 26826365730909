<div class="container-fluid">
    <div class="tab">
        <a [routerLink]="['/patientoutreach']" class="tablinks active">
            <img src="/assets/msx/outreach-white.svg">Patient Outreach
        </a>
        <a class="tablinks">
            <img src="/assets/msx/members.svg">Members & Send Lists
        </a>
    </div>
</div>
<div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
    <app-loader></app-loader>
</div>
<div class="mt-3" style="margin-left: -10px;">
    <ul ngbNav #navCustom="ngbNav" class="nav-tabs pl-4">
        <li (click)="changeTab('tab1')" [ngbNavItem]="'tab1'">
            <a ngbNavLink>Campaign Designer</a>
            <ng-template ngbNavContent>
                <form [formGroup]="campaignForm" (ngSubmit)="submitForm()">
                    <div class="campaign-form">
                        <div class="row align-items-center mb-3">
                            <div class="col-2 text-right">
                                <label>Campaign Name:</label>
                            </div>
                            <div class="col-5 px-0">
                                <input type="text" class="form-control" formControlName="campaignName"
                                    [ngClass]="{ 'is-invalid': submitted && form.campaignName.errors }"
                                    placeholder="Campaign Name" />
                            </div>
                            <div class="col-5 text-right">
                                <!-- <button type="button" (click)="previous()" class="btn btn-secondary"><img
                                        src="/assets/msx/previous.svg">Previous</button>
                                <button type="button" (click)="next()" class="btn btn-primary ml-3"><img
                                        src="/assets/msx/next.svg">Next</button> -->
                            </div>
                        </div>


                        <div class="row align-items-center mb-3">
                            <div class="col-2 text-right">
                                <label class="readonly">From:</label>
                            </div>
                            <div class="col-3 px-0">

                                <div class="input-group mb-3 col p-0">
                                    <ng-multiselect-dropdown style="width:100%" [settings]="practiceDropdownSettings"
                                        [data]="practices" (onDeSelect)="onItemDeSelect($event)"
                                        [(ngModel)]="selected_practice" [ngModelOptions]="{standalone: true}"
                                        (onSelect)="onItemSelect($event,'practice')">
                                    </ng-multiselect-dropdown>
                                </div>


                                <!-- <select (change)="selectedPractice($event)" formControlName="from"
                                    class="custom-select form-control"
                                    [ngClass]="{ 'is-invalid': submitted && form.from.errors }">
                                    <option value="">Please select</option>
                                    <option *ngFor="let p of practices" [value]="p.practiceid">{{ p.name }}</option>
                                </select> -->
                                <!-- <input type="text" class="form-control" formControlName="from" readonly /> -->
                            </div>
                            <div class="col-2 text-right">
                                <label>Subject:</label>
                            </div>
                            <div class="col-3 px-0">
                                <input type="text" class="form-control" formControlName="subject"
                                    [ngClass]="{ 'is-invalid': submitted && form.subject.errors }"
                                    placeholder="Subject" />
                            </div>
                        </div>

                        <div class="row align-items-center mb-3">
                            <div class="col-2 text-right">
                                <label class="readonly">Reply:</label>
                            </div>
                            <div class="col-3 px-0">
                                <input [ngClass]="{ 'is-invalid': submitted && inValidEmail }" type="text"
                                    class="form-control" formControlName="reply" />
                            </div>
                            <div class="col-2 text-right">
                                <label>Preheader:</label>
                            </div>
                            <div class="col-3 px-0">
                                <input type="text" class="form-control" formControlName="preheader"
                                    [ngClass]="{ 'is-invalid': submitted && form.preheader.errors }"
                                    placeholder="Preheader" />
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-2 text-right">
                            </div>
                            <div class="col-5 pl-0">
                                <button (click)="undo()" class="btn btn-secondary d-inline-flex"><img
                                        src="/assets/msx/addnew.svg" style="transform: rotate(45deg);">Cancel</button>
                                <!-- <button class="btn btn-secondary mx-3"><img src="/assets/msx/next.svg">Redo</button> -->
                                <button [disabled]="showLoader" type="submit"
                                    class="btn btn-primary mx-3 d-inline-flex"><img
                                        src="/assets/msx/save.svg">Save</button>
                            </div>
                        </div>
                    </div>



                    <div class="campaign-layout">
                        <div class="campaign-content">
                            <img class="w-100" src={{selectedTemplate?.featured_image}}>
                            <div class="campaign-text">
                                <div class="campaign-editor">
                                    <button class="btn btn-primary float-right  p-3" type="button"
                                        (click)="openEditor()"><img class="m-0" style="background-color: #fff;"
                                            src="/assets/msx/edit-blue.svg" alt="Edit Campaign"></button><br>
                                    <!-- <p>Dear Valued Patient,<br><br>
                                    April is Esophageal Cancer Awareness Month, and we at *|PRACTICENAME|* want to take
                                    this opportunity to raise awareness about this condition. Esophageal cancer affects
                                    the esophagus, the tube that connects your throat to your stomach. While it is a
                                    relatively less common cancer, it’s important to be aware of the risk factors and
                                    symptoms.<br><br>
                                    Early detection is crucial for improving treatment outcomes. If you experience
                                    persistent heartburn, difficulty swallowing, or unintended weight loss, please
                                    schedule an appointment with your doctor to discuss these concerns.<br> <br>
                                    We encourage you to learn more about esophageal cancer and its prevention methods by
                                    visiting the websites of the National Cancer Institute and the American Cancer
                                    Society. <br><br>
                                    Stay informed, take charge of your health, and don’t hesitate to reach out to us if
                                    you have any questions. <br><br>
                                    Sincerely, <br><br>
                                    The Team at *|PRACTICENAME|*</p> -->
                                    <div [innerHTML]="selectedTemplate?.html_template">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-footer">
                            <div class="brand">
                                <img src={{groupDetails?.logo}} width="250">
                            </div>
                            <p>{{groupDetails?.address1}}, {{groupDetails?.address2}}</p>
                            <p>{{groupDetails?.city}}, {{groupDetails?.state}} {{groupDetails?.postalCode}}</p>
                            <!-- <span>Quidem nemo inventore rerum voluptatem. Magnam fugiat velit omnis sint sit. Totam et
                                laudantium laborum quidem optio ad harum earum in. Voluptatibus quam nihil in mollitia id
                                eum maiores natus tempore. Perferendis tempore quibusdam provident laborum atque non placeat
                                animi magni. Sed cupiditate dolorum.</span> -->
                        </div>

                    </div>
                </form>
            </ng-template>
        </li>
        <li (click)="changeTab('tab2')" [ngbNavItem]="'tab2'">
            <a ngbNavLink>Preview & Test</a>
            <ng-template ngbNavContent>
                <div class="campaign-form mb-0">
                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label>Campaign Name:</label>
                        </div>
                        <div class="col-5 px-0">
                            <input [(ngModel)]="compaignName" type="text" class="form-control"
                                placeholder="Compaign Name" />
                        </div>
                        <div class="col-5 text-right">
                            <!-- <button class="btn btn-primary"><img src="/assets/msx/previous.svg">Previous</button>
                            <button class="btn btn-primary ml-3"><img src="/assets/msx/next.svg">Next</button> -->
                        </div>
                    </div>

                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label>Test Email:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" [(ngModel)]="testEmail" class="form-control"
                                placeholder="email@email.com" />
                        </div>
                        <div class="col-4">
                            <button [disabled]="testEmail=='' || compaignName==''" (click)="sendTestMail()"
                                class="btn btn-primary">Send Test Email</button>
                        </div>

                    </div>
                </div>
                <div class="preview-content">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs">
                        <li (click)="setPreviewType('desktop')" [ngClass]="previewType=='desktop'?'active':''"
                            class="nav-item">
                            <a data-bs-toggle="tab">
                                <img class="active" src="/assets/msx/desktrop-white.svg">
                                <img src="/assets/msx/desktop-gray.svg">
                            </a>
                        </li>
                        <li (click)="setPreviewType('mobile')" [ngClass]="previewType=='mobile'?'active':''"
                            class="nav-item">
                            <a data-bs-toggle="tab">
                                <img class="active" src="/assets/msx/mobile-white.svg">
                                <img src="/assets/msx/mobile-gray.svg">
                            </a>
                        </li>
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div *ngIf="previewType=='desktop'" class="tab-pane active" id="desktop">
                            <div class="campaign-layout">
                                <div class="campaign-content">
                                    <img class="w-100" src={{selectedTemplate?.featured_image}}>
                                    <div class="campaign-text">
                                        <div [innerHTML]="selectedTemplate?.html_template">
                                        </div>
                                    </div>
                                </div>
                                <div class="campaign-footer">
                                    <div class="brand">
                                        <img src={{groupDetails?.logo}} width="250">
                                    </div>
                                    <p>{{groupDetails?.address1}}, {{groupDetails?.address2}}</p>
                                    <p>{{groupDetails?.city}}, {{groupDetails?.state}} {{groupDetails?.postalCode}}</p>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="previewType=='mobile'" class="tab-pane active" id="mobile">
                            <div class="campaign-layout">
                                <div class="campaign-content">
                                    <img class="w-100" src={{selectedTemplate?.featured_image}}>
                                    <div class="campaign-text">
                                        <div [innerHTML]="selectedTemplate?.html_template">
                                        </div>
                                    </div>
                                    <div class="campaign-footer">
                                        <div class="brand">
                                            <img src={{groupDetails?.logo}} width="250">
                                        </div>
                                        <p>{{groupDetails?.address1}}, {{groupDetails?.address2}}</p>
                                        <p>{{groupDetails?.city}}, {{groupDetails?.state}} {{groupDetails?.postalCode}}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </li>
        <li (click)="changeTab('tab3')" [ngbNavItem]="'tab3'">
            <a ngbNavLink>Send Campaign</a>

            <ng-template ngbNavContent>
                <div class="campaign-form">
                    <div class="row">
                        <div class="col-4">
                            <div class="mb-3">
                                <label class="small">Campaign Name</label>
                                <label class="big">{{compaignName}}</label>
                            </div>
                            <div class="mb-3">
                                <label class="small">Subject Line</label>
                                <label class="big">{{selectedTemplate?.subject}}</label>
                            </div>
                            <!-- <div class="mb-3">
                                <label class="small">Recipients</label>
                                <label class="big">All Patients (75,299)</label>
                            </div> -->
                            <div class="mb-3">
                                <label class="small">Description</label>
                                <label
                                    class="xs">{{selectedTemplate?.campaign_description==undefined?compaignName:selectedTemplate.campaign_description}}</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row align-items-center mb-3">
                                <div class="col-4">
                                    <label>Send Date:</label>
                                </div>
                                <div class="col-6 px-0">
                                    <input [min]="minDate" [(ngModel)]="sendDate" type="date" class="form-control"
                                        [ngClass]="{ 'is-invalid': dateError }" />
                                </div>
                            </div>
                            <div class="row align-items-center mb-3">
                                <div class="col-4">
                                    <label>Send Time:</label>
                                </div>
                                <div class="col-3 px-0">
                                    <select [(ngModel)]="sendTime" class="form-control">
                                        <option>1:00</option>
                                        <option>2:00</option>
                                        <option>3:00</option>
                                        <option>4:00</option>
                                        <option>5:00</option>
                                        <option>6:00</option>
                                        <option>7:00</option>
                                        <option>8:00</option>
                                        <option>9:00</option>
                                        <option>10:00</option>
                                        <option>11:00</option>
                                        <option>12:00</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input [(ngModel)]="timeDuration" [checked]="timeDuration === 'AM'"
                                                value="AM" type="radio" class="form-check-input" name="TimeDuration">AM
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input [(ngModel)]="timeDuration" [checked]="timeDuration === 'PM'"
                                                value="PM" type="radio" class="form-check-input" name="TimeDuration">PM
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button [disabled]="showLoader" (click)="scheduleMail()" type="button"
                                    class="btn btn-primary"><img src="/assets/msx/schedule.svg">Schedule</button>
                                <button [disabled]="showLoader" (click)="sendNow()" type="button"
                                    class="btn btn-primary ml-3"><img src="/assets/msx/send.svg">Send
                                    Now</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="campaign-layout">
                    <div class="campaign-content">
                        <img class="w-100" src={{selectedTemplate?.featured_image}}>
                        <div class="campaign-text">
                            <div [innerHTML]="selectedTemplate?.html_template">
                            </div>
                        </div>
                    </div>
                    <div class="campaign-footer">
                        <div class="brand">
                            <img src={{groupDetails?.logo}} width="250">
                        </div>
                        <p>{{groupDetails?.address1}}, {{groupDetails?.address2}}</p>
                        <p>{{groupDetails?.city}}, {{groupDetails?.state}} {{groupDetails?.postalCode}}</p>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="navCustom"></div>

</div>

<ng-template #ReportOptions>
    <div class="container-fluid">
        <div class="row">
            <div class="col-2 text-right">
                <label style="margin-top: 10px;">Report For:</label>
            </div>
            <div class="col-5">
                <!-- <ng-select [(ngModel)]="selectedValue" (change)="selectedEntity($event)" [items]="EntityList"
                    bindLabel="Text" bindValue="Value" groupBy="Type" [searchable]="false">
                </ng-select> -->
            </div>
        </div>
    </div>
</ng-template>