import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MSXReportService } from "../../../services/msx_report.service";
import * as __ from 'underscore';
import * as Highcharts from "highcharts";
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MetricModalComponent } from '../modals/metric-modal/metric-modal.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FiltersModalV2Component } from '../modals/filters-modal-v2/filters-modal-v2.component';
import { FavoritesModalComponent } from '../modals/favorites-modal/favorites-modal.component';
import { SessionService } from '../../../services/session.service';
import { MSXChartService } from "../msx-chart-service/msx.chart.service";
import { xAsisFiltersModalComponent } from '../modals/xAsis-filters-modal/xAsis-filters-modal.component';
import { VisitsFiltersModalComponent } from '../modals/visits-filters-modal/visits-filters-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as uuid from 'uuid';
@Component({
  selector: 'app-survey-data',
  templateUrl: './survey-data.component.html',
  styleUrls: ['./survey-data.component.scss']
})
export class SurveyDataComponent implements OnInit {
  @ViewChild('chart') componentRef;
  chartRef;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };
  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public sessionService: SessionService,
    private chartService: MSXChartService,
    public router: Router,
    private route: ActivatedRoute
  ) { }
  public rowChartData = [];
  public chartData = [];
  public data = [];
  public practiceData = [];
  page = 0;
  pageCount = 0;
  perPage = 5;
  chartTitle = "";
  selectedFactors: any;
  metricName = "";
  showLoader = true;
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  oneToOneFlag = true;
  defaultMetric = {
    metricid: "B1C89C9E-4404-4AC2-B0C5-E667F350ECA2",
    metricname: "Provider Satisfaction Rating"
  }
  visitMetric = "visitsPerMonth"
  selectedPractice = "";
  favorites: any;
  metric = JSON.parse(localStorage.getItem('selectedMetric')) || this.defaultMetric
  favoriteList: any;
  additionalsettings: any;
  _datapointsType = "monthly"
  noData = false;
  resetGraphFilters = false
  surveys = {
    metricGroups: [],
    metrics: []
  }
  selectedSurvey = "";
  graphType = "line"
  careLengthQuestion: any;
  reasonForVisitQuestion: any;
  compareFactors = [];
  practiceIds = [];
  filters = {
    age: {
      value: "ALL",
      displayText: "ALL",
      minAge: 0,
      maxAge: 100
    },
    gender: "ALL",
    cptCode: "ALL",
    icdCode: "ALL",
    specialty: "ALL",
    surveyType: "ALL",
    specialtyDisplayText: "ALL",
    surveyTypeDisplayText: "ALL"
  }

  defaultFilters = JSON.parse(JSON.stringify(this.filters));

  xAxisType = "pplr";
  benchmarkData: any;
  selectedbenchMarkValue = 0;
  selectedBanchMark = "";
  groupAvg: any;
  appliedFilters = false;
  allowPagination = true;
  filterByAgeGroup = false;
  benchMarkCount = 0;
  url = ""
  _favoriteBanchMark = "";
  noShowPercentage = false;
  @Input() _favoriteList: any;
  @Input() _type = "";
  isFavorite = false;

  ngOnInit(): void {
    if (this._type !== "") {
      this.isFavorite = true;
    }
    this.route.queryParams.subscribe(params => {
      if (params['type'] != undefined && params['type'] === "visit") {
        this.url = "/visit-data";
        this.metricName = "Visits Per Month";
      }
      else if (params['type'] != undefined && params['type'] === "survey") {
        this.url = "/survey-data";
      }
      else {
        this.url = this.router.url;

      }
    });
    if (this._type !== "") {
      if (this._type === "visit") {
        this.url = "/visit-data";
        this.metricName = "Visits Per Month";
      }
      else {
        this.url = "/survey-data";
      }
    }
    if (this._favoriteList === undefined) {
      if (this.url == "/visit-data") {
        this.metricName = "Visits Per Month";
      }
      else {
        this.metricName = this.metric.metricname;
      }
      this.msxReportService.groupChangeNotifier.subscribe(
        data => {
          localStorage.setItem("selectedMetric", null);
          this.chartOptions = undefined;
          this.updateFlag = true;
        }
      );
      this.getSurveyList(true);
    }
    else {
      this.isFavorite = true;
      //this.url = "/survey-data";

      this.favoriteList = this._favoriteList;
      if (this.favoriteList.metricName == "Visits Per Provider") {
        this.visitMetric = "visitsPerProvider"
      }
      if (this.favoriteList.metricName.toLowerCase() === "no show percentage") {
        this.visitMetric = "noShowPercentage";
        this.noShowPercentage = true;
      }
      this.selectedFactors = (this.favoriteList.factors !== undefined ? this.favoriteList.factors : this.selectedFactors);
      this.metricName = (this.favoriteList.metricName !== undefined ? this.favoriteList.metricName : this.metricName);
      this.xAxisType = (this.favoriteList.xAxisType !== undefined ? this.favoriteList.xAxisType : this.xAxisType);
      this.graphType = (this.favoriteList.graphType !== undefined ? this.favoriteList.graphType : this.graphType);
      this.selectedSurvey = (this.favoriteList.selectedSurvey !== undefined ? this.favoriteList.selectedSurvey : this.selectedSurvey);
      this.metric.metricid = this.favoriteList.metricid !== undefined ? this.favoriteList.metricid : "";
      if (this.favoriteList.filters.length != 0) {
        this.filters = this.favoriteList.filters;
      }
      this.metric.metricname = this.metricName;
      if (this.selectedFactors !== undefined) {

        this._favoriteBanchMark = this.selectedFactors.selectedBanchMarkValue !== undefined ? this.selectedFactors.selectedBanchMarkValue : "";
      }
      this.appliedFilters = true;
      if (this.xAxisType == "age" && this.filters.age.displayText == "ALL") {
        this.filterByAgeGroup = true;
      }
      this.getData(true);
    }

  }
  private getSurveyList(pageLoad = false) {
    this.msxReportService.getMetricsList().subscribe(
      (data) => {
        this.surveys.metricGroups = data[0].attributes.metricGroups;
        this.surveys.metrics = data[0].attributes.metrics;
        if (this.surveys.metrics.length == 0) {
          this.showLoader = false;
          return;
        }

        const coreSurvey = __.findWhere(this.surveys.metricGroups, { surveyname: 'Core' })
        if (coreSurvey === undefined) {
          this.metric.metricid = this.surveys.metrics[0].list[0].metrics[0].metricid;
          this.metric.metricname = this.surveys.metrics[0].list[0].metrics[0].metricname;
          this.metricName = this.metric.metricname;
          this.selectedSurvey = this.surveys.metricGroups[0].surveyid;
        }
        else {
          this.selectedSurvey = coreSurvey.surveyid;
        }
        this.getData(true);
      },
      (err) => {
        this.showLoader = false;
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  getBenchMarksData(reportData) {
    let chartBenchmarks = (reportData.filter(x => x.p == "00000000-0000-0000-0000-000000000000")).map((s) => {
      return { f: s.f, b: s.b, m: s.m, n: s.n };
    });
    const nonZeroBuckets = reportData.filter(x => x.p != "00000000-0000-0000-0000-000000000000");
    const factorIds = _.uniq(nonZeroBuckets.map(i => i.f));
    for (const f of factorIds) {
      const fbRows = nonZeroBuckets.filter(x => x.f == f);
      const bucketNames = _.uniq(fbRows.map(i => i.b));
      for (const b of bucketNames) {
        const bRows = fbRows.filter(x => x.b == b);
        const nb = {
          f: bRows[0].f,
          //p: bRows[0].p,
          b: b,
          m: _.sumBy(bRows, 'm'),
          n: _.sumBy(bRows, 'n')
        };
        chartBenchmarks.push(nb);
      }
    }
    return chartBenchmarks;
  };

  getData(pageLoad = false, filterType = "") {
    this.showLoader = true;

    if (JSON.stringify(this.defaultFilters) == JSON.stringify(this.filters)) {
      this.appliedFilters = false;
    }

    this.msxReportService.GetSurveyData(this.url, this.metric.metricid, this.filters.gender, this.filters.age.minAge, this.filters.age.maxAge, "ALL"
      , "ALL", this.filters.cptCode, this.filters.specialty, this.filters.surveyType
      , this.filters.icdCode, this.xAxisType, "", "", this.noShowPercentage).subscribe(
        (data) => {
          this.chartData = data[0].attributes.data.reportData;
          this.chartTitle = data[0].attributes.data.chartTitle;
          this.practiceData = _.orderBy(data[0].attributes.data.practices, 'name', 'asc');
          this.chartData = this.chartData.map((x) => {
            x.practiceid = x.p;
            return x;
          });

          let benchMarkName = this._favoriteBanchMark == "" ? "National Average" : this._favoriteBanchMark
          this.chartData = _.orderBy(this.chartData, 'b', 'asc');
          this.data = this.chartData;
          this.benchmarkData = this.getBenchMarksData(this.chartData);
          let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
            b: benchMarkName,
          }), 'b'), ['b'])

          // if (this._favoriteBanchMark !== undefined && this._favoriteBanchMark !== "") {
          this.selectedbenchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
          this.selectedBanchMark = banchMark[0].b;
          this.benchMarkCount = banchMark[0].n;

          if (this.graphType !== "line") {
            this.chartData = this.chartData.filter((m) => m.f != "00000000-0000-0000-0000-000000000000");
          }

          // }
          if (pageLoad) {
            this.rowChartData = this.chartData;
            if (this.selectedFactors != undefined && this.selectedFactors.length != 0) {
              this.filterByFactors(this.selectedFactors);
            }
          }
          else {
            if (this.selectedFactors != undefined && this.selectedFactors.length != 0) {
              this.filterByFactors(this.selectedFactors);
            }
          }

          this.showLoader = false;
          if (this.selectedFactors == undefined && this.favoriteList === undefined || this.favoriteList.factors === undefined) {
            this.loadReport(this.chartData, "", filterType);
          }
          else {
            this.chartData.forEach(element => {
              if (element.b === "Group Average") {
                element.f = '740ED927-15C0-4BA9-B971-CD873FB0AE73'
              }
            })
          }
        },
        (err) => {
          this.showLoader = false;
          this.snackbar.show("error occurred please try again later.", "danger");
        }
      );
    if (!pageLoad) {
      this.selectedbenchMarkValue = 0;
      this.selectedBanchMark = null;
    }
  }

  loadReport(reportData, type = "", filterType = "") {
    this.groupAvg = undefined;
    if (this.graphType === "column") {
      this.perPage = 10;
    }
    else {
      this.perPage = 5;
    }
    this.chartOptions = undefined
    this.noData = false;
    reportData = _.orderBy(reportData, [user => user.b == null ? "" : user.b.toLowerCase()], ['asc']);
    if (this.isFavorite && this.graphType === "line" && this.selectedFactors === undefined && JSON.stringify(this.defaultFilters) == JSON.stringify(this.filters)) {
      this.appliedFilters = false;
    }

    if ((this.selectedFactors === undefined || this.selectedFactors === null) && type == "" && this.xAxisType === "pplr") {
      if (this.url === '/visit-data') {
        if (this.noShowPercentage) {

          let _groupAvg = _.filter(reportData, {
            b
              : 'Group Average'
          })
          this.groupAvg = _groupAvg.length == 0 ? 0 : _groupAvg[0].nsp
        }

        reportData = _.filter(reportData, {
          f
            : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
        })
      }
      else {
        reportData.forEach(element => {
          if (element.b === "Group Average") {
            element.f = '740ED927-15C0-4BA9-B971-CD873FB0AE73'
          }
        });
        if (!this.appliedFilters) {
          if (this.graphType === "line") {
            reportData = _.filter(reportData, (r) => {
              return r.b === 'Group Average' || r.b === this.selectedBanchMark;
            });
          }
          else {
            reportData = _.filter(reportData, (r) => {
              return r.b === 'Group Average';
            });
          }
        }
        else {
          reportData = _.filter(reportData, {
            f
              : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
          })
        }
      }
    }
    else if (this.isFavorite && this.xAxisType === "pplr" &&
      (
        this.selectedFactors.selected_location.length == 0
        && this.selectedFactors.selected_practice.length == 0
        && this.selectedFactors.selected_provider.length == 0
        && this.selectedFactors.selected_role.length == 0

      )) {
      this.appliedFilters = false;
      if (this.url === '/visit-data') {
        reportData = _.filter(reportData, {
          f
            : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
        })
      }
      else {
        reportData.forEach(element => {
          if (element.b === "Group Average") {
            element.f = '740ED927-15C0-4BA9-B971-CD873FB0AE73'
          }
        });
        if (!this.appliedFilters && this.practiceIds.length == 0) {

          reportData = _.filter(reportData, (r) => {
            return r.b === 'Group Average';// || r.b === this.selectedBanchMark;
          });

        }
      }

    }
    else {
      //do nothing
    }
    if (reportData.length === 0) {
      this.noData = true;;
      return;
    }
    if (reportData.length === 1 && reportData[0].b == "National Average") {
      this.noData = true;;
      return;
    }
    if (this.filterByAgeGroup) {
      reportData = reportData.map((x) => {
        x.n = parseInt(x.n);
        x.weeknumber = moment(moment(x.d, 'YYYY-MM-DD 00:00:00').day('Monday')).format('YYYY-MM-DD 00:00:00');
        x.bucketmonth = moment(x.d, 'YYYY-MM-DD 00:00:00').format('YYYY-MM-01 00:00:00');
        if (parseInt(x.b) < 18) {
          x.agegroup = "Under 18";
          x.sortorder = 0;
        }
        else if (parseInt(x.b) > 17 && parseInt(x.b) <= 24) {
          x.agegroup = "18-24";
          x.sortorder = 1;
        }
        else if (parseInt(x.b) > 24 && parseInt(x.b) <= 44) {
          x.agegroup = "25-44";
          x.sortorder = 2;
        }
        else if (parseInt(x.b) > 44 && parseInt(x.b) <= 60) {
          x.agegroup = "45-60";
          x.sortorder = 3;
        }
        else {
          x.agegroup = "Over 60"
          x.sortorder = 4;
        }
        return x;
      });
      reportData = _.orderBy(reportData, 'sortorder', 'asc');
    }
    else {
      reportData = reportData.map((x) => {
        x.n = parseInt(x.n);
        x.weeknumber = moment(moment(x.d, 'YYYY-MM-DD 00:00:00').day('Monday')).format('YYYY-MM-DD 00:00:00');
        x.bucketmonth = moment(x.d, 'YYYY-MM-DD 00:00:00').format('YYYY-MM-01 00:00:00');
        return x;
      });
    }

    let dateStart = moment(new Date(Math.min(...reportData.map(e => new Date(e.d))))).format('YYYY-MM-DD 00:00:00');
    let dateEnd = moment(new Date(Math.max(...reportData.map(e => new Date(e.d))))).format('YYYY-MM-DD 00:00:00');
    dateEnd = moment(dateEnd).endOf('month').format('YYYY-MM-DD 00:00:00');
    let nonZeroReportData = reportData.filter((m) => m.f != "-00000000-0000-0000-0000-000000000000");
    let nonZerobuckets = _.groupBy(nonZeroReportData, x => x.b);
    let chartSeriesData;
    if (this.filterByAgeGroup) {
      chartSeriesData = (_.uniqBy(reportData.filter((m) => m.f != "-00000000-0000-0000-0000-000000000000"), 'agegroup')).map((s) => {
        let filterByColumn = this._datapointsType == "monthly" ? "bucketmonth" : this._datapointsType == "weekly" ? "weeknumber" : "d";
        let currentMonth = moment(new Date(), 'YYYY-MM-DD 00:00:00').format('YYYY-MM-01 00:00:00')
        let previousMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
        let d = new Date();
        if (d.getDate() < 10) {
          currentMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
          previousMonth = moment().subtract(2, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
        }
        let nonZeroReportData = reportData.filter((m) => m.f != "-00000000-0000-0000-0000-000000000000" && m.bucketmonth == currentMonth || m.bucketmonth == previousMonth);
        let nonZerobuckets = _.groupBy(nonZeroReportData, x => x.agegroup);
        let bucketListData = _.groupBy(nonZerobuckets[s.agegroup], x => x[filterByColumn]);
        return {
          seriesName: s.agegroup,
          n: s.n,
          seriesData: (_.uniqBy(nonZerobuckets[s.agegroup], filterByColumn)).map((d) => {
            return {
              currentMonth: this.url === '/visit-data' ? _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n') : (_.round(_.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'm') / _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n'), 2)),
              previousMonth: this.url === '/visit-data' ? _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n') : (_.round(_.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'm') / _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n'), 2)),
              n_currentMonth: _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n'),
              n_previousMonth: _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n')
            };
          })
        };

      });
    }
    else {
      chartSeriesData = (_.uniqBy(reportData.filter((m) => m.f != "-00000000-0000-0000-0000-000000000000"), 'b')).map((s) => {
        let filterByColumn = this._datapointsType == "monthly" ? "bucketmonth" : this._datapointsType == "weekly" ? "weeknumber" : "d";
        const bucketListData = _.groupBy(nonZerobuckets[s.b], x => x[filterByColumn]);
        if (this.graphType !== "column" && !this.noShowPercentage) {
          return {
            seriesId: s.f,
            seriesName: s.b,
            seriesData: (_.uniqBy(nonZerobuckets[s.b], filterByColumn)).map((d) => {
              var myDate = new Date(d[filterByColumn]);
              var timezone_delay = -myDate.getTimezoneOffset() * 60 * 1000;
              myDate = new Date(myDate.getTime() + timezone_delay);
              const sm2 = _.sumBy((bucketListData[d[filterByColumn]]), 'm');
              const sn2 = _.sumBy(bucketListData[d[filterByColumn]], 'n');
              let _y = _.round(sm2 / sn2, 2)
              if (this.url === '/visit-data') {
                _y = sn2;
              }
              return {
                y: _y,
                n: sn2,
                m: sm2,
                x: parseInt(moment(myDate).format("x")),
              };
            })
          };
        }
        else {
          if (this.visitMetric == "visitsPerMonth") {
            let currentMonth = moment(new Date(), 'YYYY-MM-DD 00:00:00').format('YYYY-MM-01 00:00:00')
            let previousMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
            let d = new Date()
            if (d.getDate() < 10) {
              currentMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
              previousMonth = moment().subtract(2, 'month').startOf('month').format('YYYY-MM-01 00:00:00');
            }
            let nonZeroReportData = reportData.filter((m) => m.f != "-00000000-0000-0000-0000-000000000000" && m.bucketmonth == currentMonth || m.bucketmonth == previousMonth);
            let nonZerobuckets = _.groupBy(nonZeroReportData, x => x.b);
            let bucketListData = _.groupBy(nonZerobuckets[s.b], x => x[filterByColumn]);
            return {
              seriesName: s.b,
              n: s.n,
              seriesData: (_.uniqBy(nonZerobuckets[s.b], filterByColumn)).map((d) => {
                return {
                  currentMonth: this.url === '/visit-data' ? _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n') : (_.round(_.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'm') / _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n'), 2)),
                  previousMonth: this.url === '/visit-data' ? _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n') : (_.round(_.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'm') / _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n'), 2)),
                  n_currentMonth: _.sumBy(bucketListData[currentMonth] === undefined ? 1 : bucketListData[currentMonth], 'n'),
                  n_previousMonth: _.sumBy(bucketListData[previousMonth] === undefined ? 1 : bucketListData[previousMonth], 'n')

                };
              })
            };
          }
          else {
            let n = "n";
            if (this.noShowPercentage) {
              n = "nsp";
            }
            return {
              seriesName: s.b,
              n: _.sumBy(nonZerobuckets[s.b], n),
              seriesData: (_.uniqBy(nonZerobuckets[s.b], filterByColumn)).map((d) => {
                return {
                  n_currentMonth: _.sumBy(nonZerobuckets[s.b], n),
                };
              })
            };
          }
        }
      });

    }



    if (this.graphType === "column") {
      let _data = []
      chartSeriesData.forEach(element => {
        if (element.seriesData.length > 0) {
          _data.push(element)
        }
      });
      chartSeriesData = _data;
    }

    this.pageCount = Math.ceil(chartSeriesData.length / this.perPage);
    chartSeriesData = chartSeriesData.sort((a, b) => {
      return a.seriesName - b.seriesName;
    });
    if (this.groupAvg === undefined) {
      this.groupAvg = _.filter(chartSeriesData, {
        seriesName: 'Group Average'
      })[0]
    }
    //}
    // let _groupAvg = 0;
    // if (this.graphType === "column") {
    //   _groupAvg = this.groupAvg.seriesData[this.groupAvg.seriesData.length - 1].y
    // }

    if (chartSeriesData.length == 0) {
      this.selectedbenchMarkValue = 0;
      this.selectedBanchMark = null;
    }
    this.chartOptions = this.chartService.chartSettings(true, this.graphType, this.url, this.selectedbenchMarkValue, this.selectedBanchMark, this.noShowPercentage ? this.groupAvg : chartSeriesData.length, 0, this.benchMarkCount, this.noShowPercentage, this.selectedFactors === undefined ? "" : this.selectedFactors.selectedBanchMarkOption);
    this.chartOptions.series = [];
    chartSeriesData = __.first(__.rest(chartSeriesData, (this.page || 0) * this.perPage), this.perPage)



    if (this.graphType === "line") {
      if (this.url === '/survey-data' && this.groupAvg !== undefined) {
        let _chartSeriesData = [];
        chartSeriesData.forEach(element => {
          if (element.seriesName !== "Group Average") {
            _chartSeriesData.push(element);
          }
        });

        chartSeriesData = [this.groupAvg, ..._chartSeriesData]
        chartSeriesData = _.uniq(chartSeriesData);
      }
      let lineWidth = null;
      chartSeriesData.forEach(element => {
        _.sortBy(element.seriesData, ['x']);
      });
      chartSeriesData.forEach(element => {
        let _data = []
        element.seriesData.forEach(series => {
          _data.push([series.x, series.y, series.n])
        });
        if (this.url === '/survey-data' && this.groupAvg !== undefined) {
          this.chartOptions.series.push(
            {
              name: element.seriesName,
              data: _data.map(function (d) { var obj = { x: d[0], y: d[1], n: d[2] }; return obj; }),
              lineWidth: element.seriesName === "Group Average" ? 4 : 2,
              color: element.seriesName === "Group Average" ? '#008000' : '',
              dashStyle: element.seriesName === "Group Average" ? 'ShortDot' : '',
            }
          );
        }
        else {
          this.chartOptions.series.push(
            {
              name: element.seriesName,
              data: _data.map(function (d) { var obj = { x: d[0], y: d[1], n: d[2] }; return obj; }),
              lineWidth: 2,
              dashStyle: 'Solid'
            }
          );
        }
      });
    }
    else {

      let currentMonth = [];
      let previousMonth = [];
      let name = [];
      let currentMonthData = [];
      let previousMonthData = [];


      chartSeriesData.forEach(element => {
        currentMonth.push(element.seriesData[0] === undefined ? 0 : (isNaN(element.seriesData[0].currentMonth) ? 0 : element.seriesData[0].currentMonth))
        previousMonth.push(element.seriesData[0] === undefined ? 0 : element.seriesData[0].previousMonth)
        name.push(element.seriesName)
        currentMonthData.push({ n_currentMonth: element.seriesData[0].n_currentMonth, n: element.n, category: element.seriesName, y: element.seriesData[0] === undefined ? 0 : (isNaN(element.seriesData[0].currentMonth) ? 0 : element.seriesData[0].currentMonth) })
        previousMonthData.push({ n_previousMonth: element.seriesData[0].n_previousMonth, n: element.n, category: element.seriesName, y: element.seriesData[0] === undefined ? 0 : element.seriesData[0].previousMonth })
      });
      this.chartOptions.series = []
      if (this.visitMetric == "visitsPerMonth") {
        let _previousMonth = moment().subtract(1, "month").format('MMMM');
        let _currentMonth = moment().format('MMMM');
        let d = new Date();
        if (d.getDate() < 10) {
          _previousMonth = moment().subtract(2, "month").format('MMMM');
          _currentMonth = moment().subtract(1, "month").format('MMMM');
        }

        this.chartOptions.series.push(
          {
            color: '#08c86c',
            name: _previousMonth,
            data: previousMonthData.map(function (d) { var obj = { n: d.n_previousMonth, y: d.y }; return obj; }),
          },
          {
            color: '#ff930a',
            name: _currentMonth,
            data: currentMonthData.map(function (d) { var obj = { n: d.n_currentMonth, y: d.y }; return obj; }),

          }
        )
      }
      else {
        this.chartOptions.series.push(
          {
            color: '#08c86c',
            name: this.noShowPercentage ? "NSP" : "Total Visits",
            data: previousMonthData.map(function (d) { var obj = { y: d.n }; return obj; }),
            showInLegend: false,
          }
        )
      }
      this.chartOptions.xAxis["categories"] = name;
    }
    //this.chartTitle = "(" + moment(dateStart).format('MM/DD/YYYY') + " to " + moment(dateEnd).format('MM/DD/YYYY') + ")"
    //chartBenchmarks= chartBenchmarks,
    //this.updateFlag = true;
    setTimeout(() => {
      this.updateFlag = true;
      this.allowPagination = true;
    }, 2000);
  }

  next() {
    if (this.page < this.pageCount - 1) {
      if (this.graphType !== "line" || this.isFavorite) {
        this.data = this.data.filter((m) => m.f != "00000000-0000-0000-0000-000000000000");
      }
      this.allowPagination = false;
      setTimeout(() => {
        this.page++;
        this.loadReport(this.data);
      }, 1000);

    }


  }

  previous() {
    if (this.page > 0) {
      if (this.graphType !== "line" || this.isFavorite) {
        this.data = this.data.filter((m) => m.f != "00000000-0000-0000-0000-000000000000");
      }
      this.allowPagination = false;
      this.page--;
      this.loadReport(this.data);
    }
  }


  getMetricsList(metricId, practiceId = "", providerName = "", practiceName = "", reset = false) {
    this.msxReportService.GetSurveyData(this.url, metricId, this.filters.gender, this.filters.age.minAge, this.filters.age.maxAge, "ALL"
      , "ALL", this.filters.cptCode, this.filters.specialty, this.filters.surveyType
      , this.filters.icdCode, this.xAxisType).subscribe(
        (data) => {
          this.chartData = data[0].attributes.data.reportData;
          this.chartData = _.orderBy(this.chartData, 'd', 'asc');
          let banchMark = _.sortBy(_.uniqBy(_.filter(this.chartData, {
            b: this.selectedBanchMark == "" ? 'National Average' : this.selectedBanchMark,
          }), 'b'), ['b'])
          if (banchMark !== undefined) {
            this.selectedbenchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            this.selectedBanchMark = banchMark[0].b;
          }
          this.benchmarkData = this.getBenchMarksData(this.chartData);
          this.data = this.chartData;
          this.showLoader = false;

          if (this.selectedFactors != undefined && this.selectedFactors.length != 0) {
            this.filterByFactors(this.selectedFactors);
          }
          else {
            this.loadReport(this.chartData);

          }
        },
        (err) => {
          this.showLoader = false;
          this.snackbar.show("error occurred please try again later.", "danger");
        }
      );
  }

  public clearFilter() {
    this.selectedPractice = "";
    this.selectedFactors = undefined;
  }

  public removeFilter(name, type = "") {
    if (type === "practice") {
      this.selectedFactors.selected_practice = __.without(this.selectedFactors.selected_practice, __.findWhere(this.selectedFactors.selected_practice, { b: name.b }));
    }
    if (type === "provider") {
      this.selectedFactors.selected_provider = __.without(this.selectedFactors.selected_provider, __.findWhere(this.selectedFactors.selected_provider, { b: name }));
    }
    if (type === "location") {
      this.selectedFactors.selected_location = __.without(this.selectedFactors.selected_location, __.findWhere(this.selectedFactors.selected_location, { b: name }));
    }
    if (type === "role") {
      this.selectedFactors.selected_role = __.without(this.selectedFactors.selected_role, __.findWhere(this.selectedFactors.selected_role, { b: name }));
    }
    this.filterByFactors(this.selectedFactors);
  }
  public removeAppliedFilter(type = "") {
    if (type === "age") {
      this.filters.age.value = "ALL";
      this.filters.age.displayText = "ALL";
      this.filters.age.minAge = 0;
      this.filters.age.maxAge = 100;
      if (this.xAxisType === "age") {
        this.filterByAgeGroup = true;
      }
    }
    if (type === "gender") {
      this.filters.gender = "ALL";
    }
    if (type === "icdcode") {
      this.filters.icdCode = "ALL";
    }
    if (type === "cptcode") {
      this.filters.cptCode = "ALL";
    }
    if (type === "specialty") {
      this.filters.specialty = "ALL";
      this.filters.specialtyDisplayText = "";
    }
    if (type === "surveytype") {
      this.filters.surveyType = "ALL";
      this.filters.surveyTypeDisplayText = "";
    }
    this.appliedFilters = true;
    this.getData();
  }

  public filterByFactors(factors) {
    this.compareFactors = [];
    this.practiceIds = [];
    // if (this.graphType === "column") {
    //   this.graphType = "line";
    //   this.data = this.chartData;
    // }
    if (factors.practiceId !== "") {
      this.selectedPractice = factors.practiceId;
      this.practiceIds.push(factors.practiceId);
    }
    factors.selected_practice.forEach(element => {
      this.compareFactors.push(element.b);
      this.practiceIds.push(element.practiceid);
    });
    factors.selected_provider.forEach(element => {
      this.compareFactors.push(element.b);
    });
    factors.selected_location.forEach(element => {
      this.compareFactors.push(element.b);
    });
    factors.selected_role.forEach(element => {
      this.compareFactors.push(element.b);
    });

    if (this.compareFactors.length === 0) {
      //this.reset();
    }
    this.applyFilters();
  }

  reset() {
    if (this.xAxisType === "age") {
      this.filterByAgeGroup = true;
    }
    this.selectedPractice = "";
    this.selectedFactors = undefined;
    this.filters = {
      age: {
        value: "ALL",
        displayText: "ALL",
        minAge: 0,
        maxAge: 100
      },
      gender: "ALL",
      cptCode: "ALL",
      icdCode: "ALL",
      specialty: "ALL",
      surveyType: "ALL",
      specialtyDisplayText: "",
      surveyTypeDisplayText: ""
    }
    this.appliedFilters = false;
    this.getData(true);
    this.updateFlag = true;

  }

  isValid(val) {
    if (Array.isArray(val)) {
      this.selectedFactors = null;
    }
    if (val === null || val === undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  public selectedMatric() {
    const modalRef = this.modalService.open(MetricModalComponent, { backdrop: "static" });
    modalRef.componentInstance.surveys = this.surveys;
    modalRef.componentInstance.selectedSurvey = this.selectedSurvey;
    modalRef.componentInstance.metric = this.metric.metricid;
    modalRef.componentInstance.metricType = "survey";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      // this.xAxisType = "pplr";
      //this.graphType = "line";
      if (this.selectedSurvey != receivedEntry.selectedSurvey) {
        this.resetGraphFilters = true;
      }

      if (receivedEntry.selectedSurvey != "") {
        this.selectedSurvey = receivedEntry.selectedSurvey;
        this.metric.metricid = receivedEntry.metric;
      }
      if (receivedEntry.proceed != "") {
        this.showLoader = true;
        // this.clearFilter();
        this.getMetricsList(receivedEntry.proceed);
      }
      if (receivedEntry.name != "") {
        this.metricName = receivedEntry.name;
      }

    });
  }
  getLableName() {
    if (this.metricName == "Visits Per Provider") {
      return "Provider"
    }
    else if (this.xAxisType === "provider") {
      return "Providers"
    }
    else if (this.xAxisType === "location") {
      return "Locations"
    }
    else if (this.xAxisType === "age") {
      return "Age group"
    }
    else if (this.xAxisType === "icd10code") {
      return "ICD-10 code"
    }
    else if (this.xAxisType === "cptcode") {
      return "CPT code"
    }
    else if (this.xAxisType === "market") {
      return "Market"
    }
    else if (this.xAxisType === "specialty") {
      return "Specialty"
    }
    else if (this.xAxisType === "role") {
      return "Role"
    }
    else if (this.xAxisType === "practice") {
      return "Practice"
    }
    else {
      return "Months"
    }


  }

  applyFilters(type = "") {
    //this.graphType = "line";
    let noFilter = true;
    this.data = this.chartData;
    if (this.practiceIds.length) {
      noFilter = false;
      this.data = _.filter(this.data, (v) => _.includes(this.practiceIds, v.practiceid));
    }
    if (this.compareFactors.length) {
      if (this.selectedBanchMark !== undefined) {
        this.compareFactors.push(this.selectedBanchMark)
      }
      noFilter = false;
      this.data = _.filter(this.data, (v) => _.includes(this.compareFactors, v.b));
    }
    if (this.compareFactors.length === 0 && this.practiceIds.length == 0 && this.graphType === "line" && !this.appliedFilters) {
      this.data = this.chartData;
      this.data = _.orderBy(this.chartData, 'd', 'asc');
      this.selectedPractice = "";
      noFilter = false;
      if (this.graphType === "line") {
        this.data = _.filter(this.data, (r) => {
          return r.b === 'Group Average' || r.b === this.selectedBanchMark;
        });
      }
      else {
        this.data = _.filter(this.data, (r) => {
          return r.b === 'Group Average';
        });
      }

      this.data.forEach(element => {
        if (element.b === this.selectedBanchMark) {
          element.m = this.selectedbenchMarkValue * 10;
          element.n = 10;
        }
      });

    }
    if (type == "provider") {
      noFilter = false;
      this.graphType = "column";
      this.data = _.filter(this.data, {
        f: '740ED927-15C0-4BA9-B971-CD873FB0AE73'
      })
    }
    if (type == "location") {
      noFilter = false;
      this.graphType = "column";
      this.data = _.filter(this.data, {
        f: '3E2D7B7A-2FC9-4B3F-A112-902C3FB0925C'
      })
    }
    if (type == "practice") {
      noFilter = false;
      this.graphType = "line";
      this.data = _.filter(this.data, {
        f: '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8'
      })
    }
    if (noFilter) {
      this.selectedFactors = undefined;
    }
    this.loadReport(this.data, type, "");

  }

  public compareGraph() {
    const modalRef = this.modalService.open(FiltersModalV2Component, { backdrop: "static" });
    modalRef.componentInstance.ChartData = this.chartData;
    modalRef.componentInstance.SelectedFactors = this.selectedFactors;
    modalRef.componentInstance.selectedPractice = this.selectedPractice;
    modalRef.componentInstance.benchmarkData = this.benchmarkData;
    modalRef.componentInstance.benchMarkCount = this.benchMarkCount;
    modalRef.componentInstance.parent = "survey";
    modalRef.componentInstance.graphType = this.graphType;
    modalRef.componentInstance.practiceData = this.practiceData;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      // this.xAxisType = "pplr";
      // this.chartData = this.rowChartData;
      this.page = 0;
      // this.graphType = "line";
      this.selectedFactors = receivedEntry.parameters;
      this.selectedbenchMarkValue = receivedEntry.parameters.benchMarkValue;
      this.selectedBanchMark = receivedEntry.parameters.selectedBanchMarkValue;
      this.benchMarkCount = receivedEntry.parameters.benchMarkCount;
      this.filterByFactors(this.selectedFactors)
    });
  }

  showFilters() {
    const modalRef = this.modalService.open(VisitsFiltersModalComponent, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
    modalRef.componentInstance._filters = this.filters;
    modalRef.componentInstance.metricid = this.metric.metricid;
    modalRef.componentInstance.parent = "survey";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this._favoriteBanchMark = this.selectedBanchMark;
      this.page = 0;
      this.filterByAgeGroup = false;
      // this.graphType = "line";
      // this.xAxisType = "pplr"
      this.appliedFilters = true;
      this.filters = receivedEntry.parameters.filters;
      this.getData();
    });
  }

  xAxisFilter() {
    const modalRef = this.modalService.open(xAsisFiltersModalComponent, { size: "sm", windowClass: "modal-lg-xAxis-filter", backdrop: "static" });
    modalRef.componentInstance.dataPoints = this.xAxisType
    modalRef.componentInstance.parentPage = "survey"
    modalRef.componentInstance.noShowPercentage = this.noShowPercentage
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.dataPoints === "age" && this.filters.age.displayText == "ALL") {
        this.filterByAgeGroup = true;
      }
      else {
        this.filterByAgeGroup = false;
      }
      this.page = 0;
      this.xAxisType = receivedEntry.dataPoints;
      if (this.xAxisType === "pplr" && !this.noShowPercentage) {
        this.graphType = "line";
      }
      else {
        this.graphType = "column";
      }
      this.selectedPractice = "";
      this.selectedFactors = undefined;
      debugger
      this.getData(false, this.xAxisType === "pplr" ? "" : "xAxis");
      this.updateFlag = true;
    });
  }

  setFavorites() {
    let _graphName = this.url == "/visit-data" ? "visit" : "survey"
    let additionalsettings = {
      graphName: _graphName,
      metricName: this.metricName,
      filters: this.filters,
      factors: this.selectedFactors,
      xAxisType: this.xAxisType,
      graphType: this.graphType,
      selectedSurvey: this.selectedSurvey,
      graphId: uuid.v4(),
      Group: localStorage.getItem("selectedGroup") || "",
      metricid: this.metric.metricid,
      practiceid: "",
      questionid: "ALL",
      answerid: "ALL",
      dataPointsType: "monthly",
      dateFormat: "MMM'YY",
    }
    const modalRef = this.modalService.open(FavoritesModalComponent, { backdrop: "static" });
    //modalRef.componentInstance.name = this.graphName;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      additionalsettings["name"] = receivedEntry.parameters;
      this.msxReportService.saveFavoriteListV2(additionalsettings, _graphName).subscribe(
        (data) => {
          this.snackbar.show("Favourite added successfully.", "success");
        },
        (err) => {
          this.snackbar.show(err, "danger");
        }
      );
    });
  }

  getFilters() {
    let str = ""
    if (this.filters.age.displayText != "ALL") {
      str += "Age Group: " + this.filters.age.displayText + ", "
    }
    if (this.filters.gender != "ALL") {
      str += "Gender: " + this.filters.gender + ", "
    }
    if (this.filters.icdCode != "ALL") {
      str += "ICD-10: " + this.filters.icdCode + ", "
    }
    if (this.filters.cptCode != "ALL") {
      str += "CPT: " + this.filters.cptCode + ", "
    }
    if (this.filters.specialtyDisplayText != "ALL" && this.filters.specialtyDisplayText != "") {
      str += "Specialty: " + this.filters.specialtyDisplayText + ", "
    }
    if (this.filters.surveyTypeDisplayText != "ALL" && this.filters.surveyTypeDisplayText != "") {
      str += "Survey Type: " + this.filters.surveyTypeDisplayText + ", "
    }
    return str.replace(/,\s*$/, "");

  }

  public selectedVisitMatric() {
    const modalRef = this.modalService.open(MetricModalComponent, { backdrop: "static" });
    modalRef.componentInstance.metric = this.visitMetric;
    modalRef.componentInstance.metricType = "visit";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.visitMetric = receivedEntry.metric;
      this.metricName = receivedEntry.name;
      this.resetGraphFilters = true;
      this.selectedFactors = undefined;

      if (receivedEntry.metric == "noShowPercentage") {
        this.noShowPercentage = true;
        this.filterByAgeGroup = false;
        //  this.xAxisType = "pplr";
        // this.graphType = "line";
        this.filters = {
          age: {
            value: "ALL",
            displayText: "ALL",
            minAge: 0,
            maxAge: 100
          },
          gender: "ALL",
          cptCode: "ALL",
          icdCode: "ALL",
          specialty: "ALL",
          surveyType: "ALL",
          specialtyDisplayText: "ALL",
          surveyTypeDisplayText: "ALL"
        }

      }
      else {
        this.noShowPercentage = false;
      }
      if (receivedEntry.metric == "visitsPerProvider") {
        this.xAxisType = "pplr";
      }
      if (receivedEntry.metric == "visitsPerMonth") {
        this.xAxisType = "pplr";
      }
      this.getData(false, "")

      if (receivedEntry.metric == "visitsPerProvider" || receivedEntry.metric == "noShowPercentage") {
        this.graphType = "column";

      }
      else {
        this.graphType = "line";
      }

      this.loadReport(this.data)

    });
  }


}
