<div class="container-fluid">
    <div class="tab">
        <a [routerLink]="['/patientoutreach']" class="tablinks">
            <img src="/assets/msx/outreach.svg">Patient Outreach
        </a>
        <a class="tablinks active">
            <img src="/assets/msx/members-white.svg">Members & Send Lists
        </a>
    </div>
</div>

<div class="mt-3" style="margin-left: -10px;">
    <ul ngbNav #navCustom="ngbNav" class="nav-tabs pl-4">
        <li (click)="tabClick('members')" [ngbNavItem]="'tab1'">
            <a ngbNavLink>Members</a>
            <ng-template ngbNavContent>

                <div class="campaign-form">
                    <div class="row">
                        <div class="col-5">
                            <div class="search-div">
                                <input type="text" placeholder="Search..." class="form-control search">
                                <img src="/assets/msx/search.svg">
                            </div>
                        </div>
                        <div class="col-7 text-right">
                            <button class="btn btn-primary ml-1"><img _ngcontent-aqh-c302=""
                                    src="/assets/msx/addnew.svg">New Member</button>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="showLoader"
                            class="h-100 d-flex flex-column justify-content-center align-items-center">
                            <app-loader></app-loader>
                        </div>
                        <table class="table-striped datatable">
                            <thead>
                                <tr>
                                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                        *ngFor="let c of displayedColumns" (sort)="onSort($event)"
                                        sortable="{{ c.columnname }}">{{ c.displayname }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of resultList$ | async">
                                    <td>{{m.created_at | date: 'MM-dd-yyyy'}}</td>
                                    <td>{{m.name}}</td>
                                    <td>{{m.email}}</td>
                                    <td class="text-center">
                                        <img class="mr-3 cursor-pointer" src="/assets/msx/edit-blue.svg" alt="Edit">
                                        <img (click)="onDeleteMember(m.member_id)" class="cursor-pointer"
                                            src="/assets/msx/delete-blue.svg" alt="Delete">
                                    </td>
                            </tbody>
                        </table>
                        <div *ngIf="members?.length" class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                                [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

                            <select class="custom-select pagination"
                                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                                [(ngModel)]="tableService.pageSize">
                                <option [ngValue]="10">10 items per page</option>
                                <option [ngValue]="50">50 items per page</option>
                                <option [ngValue]="100">100 items per page</option>
                            </select>
                        </div>
                    </div>
                </div>

            </ng-template>
        </li>
        <li (click)="tabClick('groups')" [ngbNavItem]="'tab2'">
            <a ngbNavLink>Send Lists</a>

            <ng-template *ngIf="showGroupList" ngbNavContent>
                <div class="campaign-form">
                    <div class="row">
                        <div class="col-5">
                            <div class="search-div">
                                <input type="text" placeholder="Search..." class="form-control search">
                                <img src="/assets/msx/search.svg">
                            </div>
                        </div>
                        <div class="col-7 text-right">
                            <button (click)="sendList()" class="btn btn-primary ml-1"><img _ngcontent-aqh-c302=""
                                    src="/assets/msx/addnew.svg">New Send List</button>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="showLoader"
                            class="h-100 d-flex flex-column justify-content-center align-items-center">
                            <app-loader></app-loader>
                        </div>
                        <table class="table-striped datatable">
                            <thead>
                                <tr>
                                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                        *ngFor="let c of displayedColumns" (sort)="onSort($event)"
                                        sortable="{{ c.columnname }}">{{ c.displayname }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of resultList$ | async">
                                    <td>{{m.created_at | date: 'MM-dd-yyyy'}}</td>
                                    <td>{{m.name}}</td>
                                    <td>{{m.description}}</td>
                                    <td class="text-center">
                                        <img *ngIf="m.allowAction" class="mr-3 cursor-pointer"
                                            src="/assets/msx/edit-blue.svg" alt="Edit">
                                        <img *ngIf="m.allowAction" (click)="onDeleteMemberList(m.group_id)"
                                            class="cursor-pointer" src="/assets/msx/delete-blue.svg" alt="Delete">
                                    </td>
                            </tbody>
                        </table>
                        <div *ngIf="members?.length" class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                                [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

                            <select class="custom-select pagination"
                                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                                [(ngModel)]="tableService.pageSize">
                                <option [ngValue]="10">10 items per page</option>
                                <option [ngValue]="50">50 items per page</option>
                                <option [ngValue]="100">100 items per page</option>
                            </select>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template *ngIf="!showGroupList" ngbNavContent>

                <div class="campaign-form">
                    <div class="row">
                        <div class="col-7 d-flex align-items-center">
                            <label class="mr-2" style="width: 215px;">New Send List Name:</label>
                            <input [ngClass]="{ 'is-invalid':  invalidName }" [(ngModel)]="name"
                                [ngModelOptions]="{standalone: true}" type="text" class="form-control" />
                        </div>
                        <div class="col-5">
                            <button (click)="clear()" type="reset" class="btn btn-secondary">Cancel</button>
                            <button (click)="save()" type="button" class="btn btn-primary ml-3">Create</button>
                        </div>
                    </div>
                </div>

                <div class="container-fluid mb-5">
                    <h2>Criteria</h2>
                    <div class="row">
                        <div class="col-2 pr-0 text-right">
                            <label>Gender:</label>
                        </div>
                        <div *ngFor="let g of options?.genders" class="col-2 pr-0">
                            <div class="form-check-inline mr-0">
                                <label class="form-check-label">
                                    <input (change)="onCheckChange($event,'genders')"
                                        type="{{g.name=='All'?'radio':'checkbox'}}" [checked]="g.checked" [value]="g.id"
                                        class="form-check-input">{{g.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2 pr-0 text-right">
                            <label>Age Group:</label>
                        </div>
                        <ng-container *ngFor="let a of options?.age; let i=index">
                            <div class="col-2 pr-0">
                                <div class="form-check-inline mr-0">
                                    <label class="form-check-label">
                                        <input (change)="onCheckChange($event,'age')"
                                            type="{{a.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                            [checked]="a.checked" [value]="a.id">{{a.name}}
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                        </ng-container>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-2 pr-0 text-right">
                            <label>Specialty:</label>
                        </div>
                        <ng-container *ngFor="let s of options?.specialties; let i=index">
                            <div class="col-2 pr-0">
                                <div class="form-check-inline mr-0">
                                    <label class="form-check-label">
                                        <input (change)="onCheckChange($event,'specialties')"
                                            type="{{s.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                            [value]="s.id" [checked]="s.checked">{{s.name}}
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                        </ng-container>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-2 pr-0 text-right">
                            <label>Location:</label>
                        </div>
                        <ng-container *ngFor="let l of options?.locations; let i=index">
                            <div class="col-2 pr-0">
                                <div class="form-check-inline mr-0">
                                    <label class="form-check-label">
                                        <input (change)="onCheckChange($event,'location')"
                                            type="{{l.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                            [checked]="l.checked">{{l.name}}
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                        </ng-container>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-2 pr-0 text-right">
                        <label>Provider:</label>
                    </div>
                    <ng-container *ngFor="let p of options?.providers; let i=index">
                        <div class="col-2 pr-0">
                            <div class="form-check-inline mr-0">
                                <label class="form-check-label">
                                    <input (change)="onCheckChange($event,'providers')"
                                        type="{{p.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                        [value]="p.id" [checked]="p.checked">{{p.name}}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                    </ng-container>

                </div>
                <hr>
                <div class="row">
                    <div class="col-2 pr-0 text-right">
                        <label>CPT code:</label>
                    </div>
                    <ng-container *ngFor="let c of options?.cptCodes; let i=index">
                        <div class="col-2 pr-0">
                            <div class="form-check-inline mr-0">
                                <label class="form-check-label">
                                    <input (change)="onCheckChange($event,'cptCodes')"
                                        type="{{c.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                        [value]="c.id" [checked]="c.checked">{{c.name}}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                    </ng-container>

                </div>
                <hr>
                <div class="row">
                    <div class="col-2 pr-0 text-right">
                        <label>Date of last visit:</label>
                    </div>
                    <ng-container *ngFor="let l of options?.lastvisits; let i=index">
                        <div class="col-2 pr-0">
                            <div class="form-check-inline mr-0">
                                <label class="form-check-label">
                                    <input (change)="onCheckChange($event,'lastvisits')"
                                        type="{{l.name=='All'?'radio':'checkbox'}}" class="form-check-input"
                                        [value]="l.id" [checked]="l.checked">{{l.name}}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="i>0&&(i+1)%5==0" class="col-2 pr-0"></div>
                    </ng-container>

                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="'tab3'">
            <a ngbNavLink>Upload Members</a>
            <ng-template ngbNavContent>

                <div class="campaign-form">
                    <div class="row">
                        <div class="col-5">
                            <div class="search-div">
                                <input type="text" placeholder="Search..." class="form-control search">
                                <img src="/assets/msx/search.svg">
                            </div>
                        </div>
                        <div class="col-7 text-right">
                            <button class="btn btn-primary ml-1"><img _ngcontent-aqh-c302=""
                                    src="/assets/msx/addnew.svg">New Member</button>
                        </div>
                    </div>
                </div>

            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="navCustom"></div>

</div>