import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembersService } from '../../../services/member.service';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { SortableHeaderDirective } from '../../../directives/sortable.directive';
import { Observable } from 'rxjs';
import { TableService } from '../../../services/table.service';
import { WarningModalComponent } from '../../../modals/warning-modal/warning-modal.component';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  showLoader = false;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  resultList$: Observable<any[]>;
  total$: Observable<number>;

  displayedColumns = [
    { displayname: "Date Added", columnname: "created_at" },
    { displayname: "Name", columnname: "name" },
    { displayname: "Email", columnname: "email" },
    { displayname: "Edit/Delete", columnname: "" },
  ];


  constructor(
    private memberService: MembersService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    public tableService: TableService,
    public groupTableService: TableService,

  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";

  }
  showGroupList = false;
  options: any;
  name = "";
  invalidName = false;
  members = [];
  groups = [];
  _options = [
    "genders",
    "age",
    "specialties",
    "locations",
    "providers",
    "cptCodes",
    "lastvisits"

  ]
  ngOnInit(): void {
    this.getOptions();
    this.getMembers();
  }


  private getOptions() {
    this.memberService.getFilterOptions().subscribe(
      (data) => {
        this.options = data[0].attributes.data;
        this.options.genders.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.age.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.specialties.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.locations.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.providers.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.cptCodes.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
        this.options.lastvisits.map((a) => {
          a.name.toString().toLowerCase() == "all" ? a.checked = true : a.checked = false;
        });
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  onCheckChange(event, optionType) {
    this.options[optionType].forEach(element => {
      if (event.target.checked && event.target.value.toString().toLowerCase() == "all") {
        element.id.toString().toLowerCase() == 'all' ? element.checked = true : element.checked = false
      }
      else {
        if (event.target.checked && event.target.value.toString().toLowerCase() != "all" && element.id.toString().toLowerCase() == "all") {
          element.checked = false
        }
        if (element.id.toString().toLowerCase() == event.target.value.toString().toLowerCase()) {
          element.checked = event.target.checked;
        }
      }

    });

  }

  save() {
    this.invalidName = false;
    if (this.name.trim() == "") {
      this.snackbar.show("Please provide valid name.", "danger");
      this.invalidName = true;
    }
    else {
      this.memberService.saveFilterOptions(this.name, this.options).subscribe(
        (data) => {
          this.snackbar.show(data[0].attributes.message, "success")
        },
        (err) => {
          this.snackbar.show(err[0].detail, "danger");
        }
      );
    }
  }

  clear() {

    this.name = "";
    this._options.forEach(o => {
      this.options[o].forEach(element => {
        element.id.toString().toLowerCase() == "all" ? element.checked = true : element.checked = false;
      });
    });
  }

  getMembers() {
    this.showLoader = true;
    this.memberService.getMemberList().subscribe(
      (data) => {
        //this.members = data[0].attributes;
        this.members = data[0].attributes.list;
        this.tableService.DATA = this.members;
        this.tableService.page = 1;
        this.showLoader = false;
        this.sorting("created_at", "desc");
        this.showLoader = false;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }
  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
  }

  getGroups() {
    this.showLoader = true;
    this.memberService.getGroups().subscribe(
      (data) => {
        //this.members = data[0].attributes;
        this.groups = data[0].attributes.list;
        this.tableService.DATA = this.groups;
        this.tableService.page = 1;
        this.showLoader = false;
        this.sorting("created_at", "desc");
        this.showLoader = false;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  tabClick(tabName) {
    if (tabName == "groups") {
      this.showGroupList = true;
      this.displayedColumns = [
        { displayname: "Date Created", columnname: "created_at" },
        { displayname: "Name", columnname: "name" },
        { displayname: "Description", columnname: "email" },
        { displayname: "Edit/Delete", columnname: "" },
      ];
      this.getGroups();
    }
    else {
      this.displayedColumns = [
        { displayname: "Date Added", columnname: "created_at" },
        { displayname: "Name", columnname: "name" },
        { displayname: "Email", columnname: "email" },
        { displayname: "Edit/Delete", columnname: "" },
      ];
      this.getMembers();
    }
  }

  sendList() {
    this.showGroupList = false;
  }

  onDeleteMember(id) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.memberService.deleteMember(id).subscribe(
          (data) => {
            this.snackbar.show("Member deleted successfully.", "success");
            this.getMembers();
          },
          (err) => {
            //this.showLoader = false;
            this.snackbar.show(err[0].detail, "danger");
          }
        );
      }
    });
  }

  onDeleteMemberList(id) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.memberService.deleteMemberList(id).subscribe(
          (data) => {
            this.snackbar.show("Member deleted successfully.", "success");
            this.getGroups();
          },
          (err) => {
            //this.showLoader = false;
            this.snackbar.show(err[0].detail, "danger");
          }
        );
      }
    });
  }

}
