import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./services/guards/auth-guard.service";
import { PageLayoutComponent } from "./pages/pagelayout/pagelayout.component";

import { HomeComponent } from "./pages/home/home.component";
import { ChatComponent } from "./pages/chat/chat.component";
import { VideoChatComponent } from "./pages/video-chat/video-chat.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { LinkManagementComponent } from "./pages/link-management/link-management.component";
import { GoalsComponent } from "./pages/goals/goals.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { StatsResolverService } from "./pages/home/resolvers/stats-resolver.service";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { AppsComponent } from "./pages/apps/apps.component";
import { SessionComponent } from "./pages/session/session.component";
import { OnboardingLoginComponent } from "./pages/onboarding-login/onboarding-login.component";
import { AutoLoginComponent } from "./pages/auto-login/auto-login.component";
import { CareteamComponent } from "./pages/superadmin/careteam/careteam.component";
import { EmployerComponent } from "./pages/employer/employer.component";
import { PushComponent } from "./pages/push/push.component";

// Goals page resolvers
import { GoalCategoriesResolverService } from "./pages/goals/resolvers/goalcategories-resolver.service";
import { GoalFrequenciesResolverService } from "./pages/goals/resolvers/goalfrequencies-resolver.service";
import { GoalTimesResolverService } from "./pages/goals/resolvers/goaltimes-resolver.service";
import { GoalTypesResolverService } from "./pages/goals/resolvers/goaltypes-resolver.service";

import { ConfidentialityStatementResolverService } from "./resolvers/confidentiality-statement-resolver.service";
import { PatientComponent } from "./pages/patient/patient.component";
import { DetailsComponent } from "./pages/patient/details/details.component";
import { UserResolverService } from "./resolvers/user-resolver.service";
import { CareTeamsResolverService } from "./resolvers/careteams-resolver.service";
import { SettingsComponent } from "./pages/settings/settings.component";
import { BulkuploadComponent } from "./pages/patient/bulkupload/bulkupload.component";
import { DiagnosticsComponent } from "./pages/diagnostics/diagnostics.component";
import { PatientDashboardComponent } from "./pages/patient-dashboard/patient-dashboard.component";
import { WaitingRoomComponent } from "./pages/waiting-room/waiting-room.component";
import { WaitingRoomDashboardComponent } from "./pages/waiting-room-dashboard/waiting-room-dashboard.component";
import { WaitingRoomReferralsComponent } from "./pages/waiting-room-referrals/waiting-room-referrals.component";
import { SettingsComponent as AdminSettingComponent } from "./pages/superadmin/settings/settings.component";
import { TelerxListingComponent } from "./pages/teleRx/telerx-listing/telerx-listing.component";
import { TelerxDetailComponent } from "./pages/teleRx/telerx-detail/telerx-detail.component";
import { AnalyticsListingComponent } from "./pages/superadmin/analytics-listing/analytics-listing.component";
import { AnalyticsDashboardComponent } from "./pages/superadmin/analytics-dashboard/analytics-dashboard.component";
import { BadWordComponent } from "./pages/superadmin/badword/badword.component";
import { LocationComponent } from "./pages/location/location.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { VisitDataComponent } from "./pages/visit-data/visit-data.component";
import { PatientVisitsDataComponent } from "./pages/MSX/patient-visits-data/patient-visits-data.component";
import { VisitsDataComponent } from "./pages/MSX/patient-visits-data/visits-data/visits-data.component";
import { SurveyDataComponent } from "./pages/MSX/survey-data/survey-data.component";
import { PatientExperienceComponent } from "./pages/MSX/patient-experience/patient-experience.component";
import { ServiceRecoveryComponent } from "./pages/service-recovery/service-recovery.component";
import { MsxReportsComponent } from "./pages/MSX/msx-reports/msx-reports.component";
import { PatientBillingComponent } from "./pages/MSX/patient-billing/patient-billing.component";
import { BillingDataComponent } from "./pages/MSX/billing-data/billing-data.component";
import { FavoritesComponent } from "./pages/MSX/favorites/favorites.component";
import { AdministrationComponent } from "./pages/MSX/administration/administration.component";
import { EscalateKeywordsComponent } from "./pages/service-recovery/escalate-keywords/escalate-keywords.component";
import { EditLinkComponent } from "./pages/MSX/social-media/edit-link/edit-link.component";
import { ReviewsComponent } from "./pages/MSX/social-media/reviews/reviews/reviews.component";
import { ActivityComponent } from "./pages/MSX/social-media/activity/activity/activity.component";
import { AddLinkComponent } from "./pages/MSX/social-media/add-link/add-link.component";
import { ScheduleReportComponent } from "./pages/MSX/administration/schedule-report/schedule-report.component";
import { CampaignComponent } from "./pages/campaign/campaign.component";
import { PatientOutreachComponent } from "./pages/MSX/patient-outreach/patient-outreach.component";
import { UnsubscribeComponent } from "./pages/MSX/unsubscribe/unsubscribe.component";
import { MembersComponent } from "./pages/MSX/members/members.component";
const appRoutes: Routes = [
    {
        path: "",
        component: LoginComponent,
        children: [{ path: "login", component: LoginComponent }],
    },
    {
        path: "reset-password/:userId",
        component: ResetPasswordComponent,
    },
    {
        path: "create-password/:userId",
        component: ResetPasswordComponent,
    },
    {
        path: "",
        component: PageLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["admin", "provider", "superprovider", "superadmin"],
        },
        resolve: {
            notice: ConfidentialityStatementResolverService,
            careteams: CareTeamsResolverService,
        },
        children: [
            {
                path: "home",
                component: HomeComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider", "superadmin"],
                },
                resolve: {
                    stats: StatsResolverService,
                },
            },
            {
                path: "chat",
                component: ChatComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["communications"]
                },
            },
            {
                path: "chat/:id",
                component: ChatComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["communications"]
                },
            },
            {
                path: "video-chat",
                component: VideoChatComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["communications"]
                },
            },
            {
                path: "orders",
                component: OrdersComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "link-management",
                component: LinkManagementComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "goals",
                component: GoalsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
                resolve: {
                    // goals: GoalsResolverService,
                    categories: GoalCategoriesResolverService,
                    frequencies: GoalFrequenciesResolverService,
                    times: GoalTimesResolverService,
                    types: GoalTypesResolverService,
                },
            },
            {
                path: "reports",
                component: ReportsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "patients",
                component: PatientComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                },
            },
            {
                path: "patient/details",
                component: DetailsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                },
            },
            {
                path: "tele-features",
                component: AppsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "session/:providerdashboardurl",
                component: SessionComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
                pathMatch: "prefix",
            },
            {
                path: "setting",
                component: SettingsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "location",
                component: LocationComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin"],
                },
            },
            {
                path: "careteams",
                component: CareteamComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin"],
                },
            },
            {
                path: "employers",
                component: EmployerComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "superprovider"],
                },
            },
            {
                path: "notifications",
                component: PushComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superprovider"],
                },
            },
            {
                path: "bulkupload",
                component: BulkuploadComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },
            },
            {
                path: "waiting-room",
                component: WaitingRoomDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
            },
            {
                path: "waiting-room/:id",
                component: WaitingRoomComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
            },
            {
                path: "service-recovery",
                component: ServiceRecoveryComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
            },
            {
                path: "service-recovery/escalate-keywords",
                component: EscalateKeywordsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
            },

            {
                path: "waiting-room-referrals/:id",
                component: WaitingRoomReferralsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                },
            },

            {
                path: "admin",
                component: AdminSettingComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "telerx",
                component: TelerxListingComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "telerx/details/:id",
                component: TelerxDetailComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "analytics-listing",
                component: AnalyticsListingComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "analytics-dashboard",
                component: AnalyticsDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "badwords",
                component: BadWordComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["superadmin"],
                },
            },
            {
                path: "visit-data",
                component: VisitsDataComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["experience_survey"]
                }
            },
            {
                path: "patient-visits",
                component: PatientVisitsDataComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["experience_survey"]
                }
            },
            {
                path: "survey-data",
                component: SurveyDataComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["experience_survey"]
                }
            },
            {
                path: "favorites",
                component: FavoritesComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["revenue"]
                }

            },
            {
                path: "patient-billing",
                component: PatientBillingComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["revenue"]
                }
            },
            {
                path: "billing-data",
                component: BillingDataComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["revenue"]
                }
            },


            {
                path: "patient-experience",
                component: PatientExperienceComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                },
            },
            {
                path: "msx/reports",
                component: MsxReportsComponent,
                canActivate: [AuthGuard],
                data: {
                    dashboadrmodule: ["experience_survey"]
                }
            },
            {
                path: "administration",
                component: AdministrationComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },

            },
            {
                path: "schedule-report",
                component: ScheduleReportComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["admin", "provider", "superprovider"],
                },

            },
            {
                path: "media/add-link",
                component: AddLinkComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                }

            },
            {
                path: "media/edit-link",
                component: EditLinkComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                }

            },
            {
                path: "media/reviews",
                component: ReviewsComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                }

            },
            {
                path: "media/activity",
                component: ActivityComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ["provider", "superprovider"],
                    dashboadrmodule: ["experience_survey"]
                }

            },
            { path: "campaign", component: CampaignComponent },
            { path: "members", component: MembersComponent },
            { path: "patientoutreach", component: PatientOutreachComponent },


        ],
    },
    {
        path: "patient-dashboard/:id",
        component: PatientDashboardComponent,
        canActivate: [AuthGuard],
        data: {
            role: ["admin", "provider", "superprovider"],
        },
        resolve: {
            user: UserResolverService,
        },
    },
    { path: "onboarding", component: OnboardingLoginComponent },
    { path: "autologin/:tokenid", component: AutoLoginComponent },
    { path: "diagnostics", component: DiagnosticsComponent },
    { path: "page-not-found", component: PageNotFoundComponent },
    { path: "unsubscribe", component: UnsubscribeComponent },
    { path: "**", redirectTo: "/page-not-found" },





];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" });
